// Can also be used with $(document).ready()
$(window).load(function () {
  $(".flexslider").flexslider({
    animation: "slide",
    slideshowSpeed: 10000,
  });
});

$(function () {
  // Handler for .ready() called.

  $(".touch .meta a").bind("click", toogleSubnavigation);

  $(".touch .main li a").bind("mouseenter", toogleSubnavigation);

  $(".touch .sub").bind("mouseleave", toogleSubnavigation);

  $("body").click(function () {
    $("nav.sub").hide();
  });

  $(".phone_icon").bind("click mouseenter", function () {
    if ($(".quickcontact .infos").css("display") != "block") {
      $(".quickcontact .infos").fadeIn(500);
    } else {
      $(".quickcontact .infos").fadeOut(500);
    }
    return false;
  });

  $("nav.sub").click(function (event) {
    event.stopPropagation();
  });

  $(".dropboxToggle").click(function () {
    $(this).parent().parent().find(".drop").slideToggle(300);
    return false;
  });

  $(".back").click(function (event) {
    event.preventDefault();
    history.back(1);
  });

  $(".no-touch .product .productImg").bind("mouseenter", productDetails);
  $(".touch .product .productImg").bind("click", productDetails);

  function productDetails() {
    $(".details").css("display", "none");

    var p = $(this).parent();
    p.find(".details").css("display", "block");

    p.find(".details").bind("mouseleave click", function () {
      $(this).css("display", "none");
    });
  }

  $("#privatumzug, #geschaeftsumzug, #lagerumzug, #andere, #transport").bind("click", switchForm);

  function switchForm() {
    if ($(this).attr("id") == "privatumzug" || $(this).attr("id") == "geschaeftsumzug" || $(this).attr("id") == "transport" || $(this).attr("id") == "andere") $("#privat, #geschaeft").slideUp();

    if ($(this).attr("id") == "privatumzug") $("#privat").slideDown();

    if ($(this).attr("id") == "geschaeftsumzug") $("#geschaeft").slideDown();

    if ($(this).attr("id") == "lagerumzug" && $(this).is(":checked")) {
      $("#lager").slideDown();
    } else {
      $("#lager").slideUp();
    }
  }

  // ACCORDION
  $(".accordion h2").click(function () {
    $(".accordion-content").slideUp(600);
    if ($(this).parent().find(".accordion-content").css("display") == "none") $(this).parent().find(".accordion-content").slideDown(600);
  });

  $(".fancybox a").fancybox({
    openEffect: "fade",
    openSpeed: 500,

    closeEffect: "fade",
    closeSpeed: 500,

    nextEffect: "fade",
    nextSpeed: 500,

    prevEffect: "fade",
    prevSpeed: 500,

    helpers: {
      title: {
        type: "inside",
      },
    },
  });

  $("a.fancybox").fancybox({
    openEffect: "fade",
    openSpeed: 200,

    closeEffect: "fade",
    closeSpeed: 200,

    nextEffect: "fade",
    nextSpeed: 200,

    prevEffect: "fade",
    prevSpeed: 200,

    helpers: {
      title: {
        type: "inside",
      },
    },
  });

  $(".accordion-title").bind("click", function () {
    if (!$(this).parent().hasClass("active")) {
      $(".accordion-section").removeClass("active");
      $(this).parent().addClass("active");
    } else {
      $(".accordion-section").removeClass("active");
    }

    accordionRender();
  });

  function accordionRender() {
    $(".accordion-section .accordion-content").slideUp(500);
    $(".accordion-section.active .accordion-content").slideDown(500);
  }
});

var openNav;

function toogleSubnavigation() {
  var open = $("nav.sub").css("display") == "block";
  var subNav = $(this).parent().attr("id");
  var subItems = $(".subnavigation ul#" + subNav + " li");

  if ($(this).parent().attr("id") == openNav || !subItems.length) {
    // close
    $("nav.sub").fadeOut(200);
    openNav = "";
  } else {
    // open		openNav = subNav;

    if ($(".subnavigation ul#" + subNav).css("visibility") != "hidden") {
      if (subItems.length) {
        $(".subnavigation > ul").hide();
        $(".banner").hide();

        $(".subnavigation ul#" + subNav).fadeIn(100);
        $(".b" + subNav).show();
        $("nav.sub").fadeIn(400);
        openNav = subNav;
      }
    }
  }

  return $(".subnavigation ul#" + subNav).css("visibility") == "hidden" || !subItems.length;
}

// FORM FIELDS: CHECK
$(".btn-submit.offer").click(function () {
  var form = $("form");

  var errorCheck = false;
  form.find("input").removeClass("attn");
  form.find("textarea").removeClass("attn");
  // check required fields:
  form.find(".required").each(function () {
    if ($(this).val() == "") {
      $(this).addClass("attn");
      errorCheck = true;
    }
  });

  // check email:
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  var emailAddress = form.find("input[name=email]").val();
  if (emailAddress != undefined && reg.test(emailAddress) == false) {
    form.find("input[name=email]").addClass("attn");
    errorCheck = true;
  }

  var about = $('input:radio[name="umzugsart[]"], input:checkbox[name="umzugsart[]"]').is(":checked");

  if (!about) {
    errorCheck = true;
    $(".about").addClass("attn");
  } else {
    $(".about").removeClass("attn");
  }

  var aufmerksam = $('select[name="aufmerksam"] option:selected').val() != "------";

  if (!aufmerksam) {
    errorCheck = true;
    $(".aufmerksam").addClass("attn");
  } else {
    $(".aufmerksam").removeClass("attn");
  }

  if (!errorCheck) {
    //form.submit();
    return true;
  }
  return false;
});

// FORM FIELDS: CHECK
$(".btn-submit.contest").click(function () {
  var form = $("form");

  var errorCheck = false;
  //form.find("input").removeClass('attn');
  //form.find("textarea").removeClass('attn');

  $("form .attn").removeClass("attn");

  // check required fields:
  form.find(".required").each(function () {
    if ($(this).val() == "") {
      $(this).addClass("attn");
      errorCheck = true;
    }
  });

  // check email:
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  var emailAddress = form.find("input[name=email]").val();
  if (emailAddress != undefined && reg.test(emailAddress) == false) {
    form.find("input[name=email]").addClass("attn");
    errorCheck = true;
  }

  if (!$("input[name=preis]").is(":checked")) {
    $("input[name=preis]").parent().addClass("attn");
    errorCheck = true;
  }

  if (!errorCheck) {
    //form.submit();
    return true;
  }
  return false;
});

$(".menu").click(function () {
  $(".pagenav").addClass("is-active");
  setTimeout(function () {
    $(".pagenav .wrapper").addClass("show");
    $("html").addClass("nav-show");
  }, 50);
  return false;
});

$(".pagenav .hamburger").click(function () {
  $(".pagenav .wrapper").removeClass("show");
  setTimeout(function () {
    $(".pagenav").removeClass("is-active");
    $("html").removeClass("nav-show");
  }, 400);
  return false;
});

$(".sub-toggle").click(function () {
  $(this).parent().toggleClass("active");
});
